
import { computed, defineComponent, PropType, ref } from 'vue';
import MedicalTestCard from '@/components/MedicalTestCard.vue';
import { FilterMatchMode } from 'primevue/api';
import { MedicalTestTableDto, MedicalTestTypeValue } from '@/models/MedicalTest';
import dateFormat from '@/helpers/DateFormat.helper';
import Tag from 'primevue/tag';
import EmptyState from './EmptyState.vue';
import { useProfileStore } from '../store/profile.module';
import HealzButton from './healz/ui/HealzButton.vue';
import { Patient } from '@/models/Patient';
import MedicalTestCreateUpdateFormModal from '@/components/MedicalTestCreateUpdateFormModal.vue';

export default defineComponent({
  components: { MedicalTestCard, Tag, EmptyState, HealzButton, MedicalTestCreateUpdateFormModal },
  emits: ['create-medical-test-click', 'medical-test-view-detail-click', 'new-medical-test'],
  props: {
    medicalTests: {
      type: Array as () => MedicalTestTableDto[],
      required: true,
    },
    tableList: {
      type: Boolean,
      required: true,
      default: () => true,
    },
    patient: {
      // this is needed for edit medical tests. If we don't have the patient, we can't edit the medical test
      type: Object as PropType<Patient>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const filters = ref();
    const storeProfile = useProfileStore();
    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);
    const rowHover = function (data: any) {
      return userCanSeeMedicalTestDetail(data) ? 'healz-datatable-clickable-row' : '';
    };
    const showPaginator = computed(() => props.medicalTests && props.medicalTests.length > 10);
    const editMedicalTestModalRef = ref();
    const medicalTestOnEdit = ref<MedicalTestTableDto | null>(null);
    const initFilters = () => {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: 'filterEqualDate' },
      };
    };
    initFilters();

    function userCanEditMedicalTest(medicalTest: MedicalTestTableDto) {
      if (isAdminUser.value || isReceptionistUser.value) {
        return true;
      }

      if (!storeProfile.userId || !medicalTest.specialist || !isSpecialistUser.value) {
        return false;
      }

      return medicalTest.specialist.id === storeProfile.userId;
    }

    function userCanSeeMedicalTestDetail(medicalTest: MedicalTestTableDto) {
      if (!storeProfile.userId || !medicalTest.specialist || !isSpecialistUser.value) {
        return false;
      }
      if (userCanEditMedicalTest(medicalTest)) {
        return true;
      }
      return medicalTest.viewerSpecialists.find((viewerSpecialist) => viewerSpecialist.id === storeProfile.userId);
    }

    const onMedicalTestDetail = (medicalTest: MedicalTestTableDto) => {
      if (!userCanSeeMedicalTestDetail(medicalTest)) {
        return;
      }
      emit('medical-test-view-detail-click', medicalTest);
    };

    const onNewMedicalTestClicked = () => {
      emit('create-medical-test-click');
    };

    const onMedicalTestEdit = (medicalTest: MedicalTestTableDto) => {
      medicalTestOnEdit.value = medicalTest;
      editMedicalTestModalRef.value?.openDialog(medicalTest);
    };

    const getTagFromTestType = (type: MedicalTestTypeValue) => {
      if (type === MedicalTestTypeValue.CALORIMETRY) {
        return 'p-tag--calorimetria';
      } else {
        return 'p-tag--cgm';
      }
    };
    return {
      filters,
      dateFormat,
      showPaginator,
      isSpecialistUser,
      editMedicalTestModalRef,
      medicalTestOnEdit,
      rowHover,
      onMedicalTestDetail,
      onNewMedicalTestClicked,
      onMedicalTestEdit,
      initFilters,
      getTagFromTestType,
      userCanEditMedicalTest,
      userCanSeeMedicalTestDetail,
    };
  },
});
