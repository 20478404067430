import { EventType } from '@/models/Event';
import {
  BareRecommendation,
  MedicalTest,
  MedicalTestCreateForm,
  MedicalTestRecommendation,
  MedicalTestTableDto,
  OverviewRecommendation,
} from '@/models/MedicalTest';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useProfileStore } from '@/store/profile.module';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';

let axiosInstance: AxiosInstance;
const storeProfile = useProfileStore();

interface SpecialistsUpdateData {
  specialistId: string;
  viewerSpecialistIds: string[];
}

class MedicalTestService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async findAll(patientId: string): Promise<MedicalTestTableDto[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<MedicalTestTableDto[]>(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[MedicalTestService] Succeed : request findAll. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[MedicalTestService] Error: request findAll`);
      return handleError(error);
    }
  }

  async find(patientId: string, medicalTestId: string): Promise<MedicalTest | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<MedicalTest>(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[MedicalTestService] Succeed : request find. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[MedicalTestService] Error: request find with medicalTestId '${medicalTestId}'`);
      return handleError(error);
    }
  }

  async create(
    patientId: string,
    medicalTestId: string,
    data: MedicalTestCreateForm,
  ): Promise<MedicalTest | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        medicalTestTypeId: medicalTestId,
      },
    };
    try {
      const response = await axiosInstance.post<MedicalTest>(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest?accountId=${storeProfile.accountId}`,
        data,
        options,
      );

      console.debug(`>[MedicalTestService] Succeed : request create. Response data: `, response.data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async update(
    patientId: string,
    medicalTestId: string,
    data: MedicalTest,
    specialistsUpdateData?: SpecialistsUpdateData,
  ): Promise<MedicalTest | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };
    try {
      const response = await axiosInstance.put<MedicalTest>(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}?accountId=${storeProfile.accountId}`,
        data,
        options,
      );

      console.debug(`>[MedicalTestService] Succeed : request create. Response data: `, response.data);
      if (specialistsUpdateData) {
        await axiosInstance.put<MedicalTest>(
          `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/specialists?accountId=${storeProfile.accountId}`,
          specialistsUpdateData,
          options,
        );
      }
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async addObservations(
    patientId: string,
    medicalTestId: string,
    observations: string,
  ): Promise<MedicalTest | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.put(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/observations?accountId=${storeProfile.accountId}`,
        { value: observations },
        options,
      );
      console.debug(`>[MedicalTestService] Succeed : request addObservations. Response data: `, response.data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async addActionsToBeTaken(
    patientId: string,
    medicalTestId: string,
    actionsToBeTaken: string,
  ): Promise<MedicalTest | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.put(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/actions?accountId=${storeProfile.accountId}`,
        { value: actionsToBeTaken },
        options,
      );
      console.debug(`>[MedicalTestService] Succeed : request addActionsToBeTaken. Response data: `, response.data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }
  async getRecommendations(
    patientId: string,
    medicalTestId: string,
  ): Promise<MedicalTestRecommendation | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<MedicalTestRecommendation>(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/recommendation?accountId=${storeProfile.accountId}`,
        options,
      );
      console.debug(`>[MedicalTestService] Succeed : request getRecommendations. Response data: `, response.data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateEventRecommendations(
    medicalTestId: string,
    patientId: string,
    type: EventType,
    recommendations: BareRecommendation[],
  ) {
    axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        type,
      },
    };

    const data = recommendations;

    try {
      const networkResponse = await axiosInstance.put<MedicalTestRecommendation>(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/eventRecommendations?accountId=${storeProfile.accountId}`,
        data,
        options,
      );
      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request update event recommendation with medicalTestId '${medicalTestId}'`);
      return handleError(error);
    }
  }

  async updateEventAssessment(medicalTestId: string, patientId: string, type: EventType, assessment: string) {
    axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        type,
      },
    };

    const data = { assessment };

    try {
      const networkResponse = await axiosInstance.put<MedicalTestRecommendation>(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/eventRecommendations/assessment?accountId=${storeProfile.accountId}`,
        data,
        options,
      );
      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request update event assessment with medicalTestId '${medicalTestId}'`);
      return handleError(error);
    }
  }

  async addOverviewRecommendations(
    patientId: string,
    medicalTestId: string,
    overviewRecommendations: OverviewRecommendation[],
  ): Promise<OverviewRecommendation[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.put(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/overviewRecommendations?accountId=${storeProfile.accountId}`,
        overviewRecommendations,
        options,
      );
      console.debug(
        `>[MedicalTestService] Succeed : request addOverviewRecommendations. Response data: `,
        response.data,
      );
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async cancelMedicalTest(medicalTestId: string, patientId: string): Promise<boolean | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    // const options: AxiosRequestConfig = {
    //   params: {
    //     patientId,
    //   },
    // };

    try {
      const response = await axiosInstance.put(
        `${process.env.VUE_APP_API_MEDICAL_TEST_SERVICE}/medicalTest/${medicalTestId}/medicalTestEvent/CANCEL?patientId=${patientId}&accountId=${storeProfile.accountId}`,
      );
      console.debug(`>[MedicalTestService] Succeed : request cancelMedicalTest. Response data: `, response.data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }
}

export const medicalTestService = new MedicalTestService();
