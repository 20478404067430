
import dateFormat from '@/helpers/DateFormat.helper';
import { MedicalTestTableDto, MedicalTestTypeValue } from '@/models/MedicalTest';
import { defineComponent, PropType, computed } from 'vue';
import HealzButton from './healz/ui/HealzButton.vue';

export default defineComponent({
  components: { HealzButton },
  emits: ['medical-test-view-detail-click'],
  props: {
    medicalTest: {
      type: Object as PropType<MedicalTestTableDto>,
      required: true,
    },
    disableButton: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(props, { emit }) {
    const date = computed(() => dateFormat.formatDate(dateFormat.utcToUserTimezone(props.medicalTest.date)));

    const getColorClass = (type: MedicalTestTypeValue) => {
      if (type === MedicalTestTypeValue.CALORIMETRY) {
        return 'calorimetry';
      } else {
        return 'cgm';
      }
    };

    const onMedicalTestDetail = () => {
      emit('medical-test-view-detail-click');
    };
    return { date, getColorClass, onMedicalTestDetail };
  },
});
