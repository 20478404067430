import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_Button, {
    id: _ctx.id,
    type: "button",
    class: _normalizeClass([_ctx.disabled ? 'p-disabled p-disabled--tooltip' : '', _ctx.buttonClass]),
    tabindex: _ctx.disabled ? -1 : undefined,
    label: _ctx.buttonText,
    loading: _ctx.loading,
    onClick: [
      _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick($event))),
      _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onClickStop($event)), ["stop"]))
    ]
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["id", "class", "tabindex", "label", "loading"])), [
    [
      _directive_tooltip,
      _ctx.disabled ? _ctx.tooltipText : undefined,
      void 0,
      { bottom: true }
    ]
  ])
}