import { MedicalTestCreateFormDto } from '@/models/MedicalTest';
import { isObjectValid, required, ValidationResult } from './form';

export interface NewMedicalTestBaseFormValidity {
  name: ValidationResult;
  testType: ValidationResult;
  specialistId: ValidationResult;
}

export function isNewCgmFormValid(form: NewMedicalTestBaseFormValidity) {
  return isObjectValid<NewMedicalTestBaseFormValidity>(form);
}

export function newMedicalTestBaseForm(medicalTest: MedicalTestCreateFormDto): NewMedicalTestBaseFormValidity {
  const name = required(medicalTest.name);
  const testType = required(medicalTest.testType);
  const specialistId = required(medicalTest.specialistId);

  return {
    name,
    testType,
    specialistId,
  };
}

export interface NewCalorimetryFormValidity extends NewMedicalTestBaseFormValidity {
  date: ValidationResult;
  hour: ValidationResult;
}

export function isNewCalorimetryFormValid(form: NewCalorimetryFormValidity) {
  return isObjectValid<NewCalorimetryFormValidity>(form);
}

export function newCalorimetryForm(medicalTest: MedicalTestCreateFormDto): NewCalorimetryFormValidity {
  const name = required(medicalTest.name);
  const testType = required(medicalTest.testType);
  const specialistId = required(medicalTest.specialistId);
  const date = required(medicalTest.date);
  const hour = required(medicalTest.hour);

  return {
    name,
    testType,
    specialistId,
    date,
    hour,
  };
}
