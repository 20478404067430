
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { Patient } from '@/models/Patient';
import { medicalTestService } from '@/services/MedicalTestService';
import {
  getMedicalTestId,
  MedicalTestCreateForm,
  MedicalTestCreateFormDto,
  MedicalTestState,
  MedicalTestTableDto,
  MedicalTestTypeValue,
} from '@/models/MedicalTest';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import {
  isNewCalorimetryFormValid,
  isNewCgmFormValid,
  newCalorimetryForm,
  newMedicalTestBaseForm,
} from '@/validation/medicalTestForm';
import { ServiceError } from '@/services/util/ServiceError';
import { userService } from '@/services/UserService';
import { UserRole, UserTableDto } from '@/models/User';
import { useProfileStore } from '../store/profile.module';
import moment from 'moment';
import i18n from '@/i18n';

export default defineComponent({
  emits: ['new-medical-test'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
    medicalTestList: {
      type: Array as PropType<MedicalTestTableDto[]>,
      required: true,
    },
    medicalTestOnEdit: {
      type: Object as PropType<MedicalTestTableDto | null>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const toast = useToast();
    const storeProfile = useProfileStore();
    const userIsSpecialist = computed(() => storeProfile.isSpecialist);
    const userProfile = computed(() => storeProfile.getProfile);
    const showDialog = ref(false);
    const showValidation = ref(false);
    const submitting = ref(false);
    const IsNameInputPristine = ref(true);
    const newMedicalTestForm: Ref<MedicalTestCreateFormDto> = ref({} as MedicalTestCreateFormDto);
    const validatedMedicalTestBaseForm = computed(() => newMedicalTestBaseForm(newMedicalTestForm.value));
    const validatedCalorimetryForm = computed(() => newCalorimetryForm(newMedicalTestForm.value));
    const editMode = computed(() => !!props.medicalTestOnEdit);
    const displayInlineWarning = computed(
      () => newMedicalTestForm.value.testType == MedicalTestTypeValue.CGM && existActiveCgm.value && !editMode.value,
    );
    const confirmButtonLabel = computed(() => (editMode.value ? t('common.save') : t('common.create')));
    const modalLabel = computed(() => (editMode.value ? t('medicalTest.edit') : t('medicalTest.new')));
    const valid = computed(() => {
      if (!newMedicalTestForm.value || !newMedicalTestForm.value.testType) {
        return false;
      }

      return (
        (newMedicalTestForm.value.testType == MedicalTestTypeValue.CGM &&
          isNewCgmFormValid(validatedMedicalTestBaseForm.value)) ||
        (newMedicalTestForm.value.testType == MedicalTestTypeValue.CALORIMETRY &&
          isNewCalorimetryFormValid(validatedCalorimetryForm.value))
      );
    });
    const medicalTestTypeOptions = ref([
      { label: t(`medicalTestTypes.${MedicalTestTypeValue.CALORIMETRY}`), value: MedicalTestTypeValue.CALORIMETRY },
      { label: t(`medicalTestTypes.${MedicalTestTypeValue.CGM}`), value: MedicalTestTypeValue.CGM },
    ]);

    const onSpecialistChange = (event: any) => {
      const validViewersIds = newMedicalTestForm.value.viewerSpecialistIds?.filter((id) => id !== event.value);

      newMedicalTestForm.value.viewerSpecialistIds = validViewersIds;
    };

    const specialistOptions: Ref<UserTableDto[] | undefined> = ref();

    const selectableSpecialistOptions = computed(() => {
      if (!specialistOptions.value) return [];

      return specialistOptions.value.filter(
        (specialist) => specialist.professionalId !== newMedicalTestForm.value.specialistId,
      );
    });

    const existActiveCgm = computed(
      () =>
        props.medicalTestList &&
        props.medicalTestList.filter(
          (test: MedicalTestTableDto) =>
            test.medicalTestTypeValue === MedicalTestTypeValue.CGM &&
            test.state !== MedicalTestState.FINISHED &&
            test.state !== MedicalTestState.CANCELED,
        ).length > 0,
    );
    const cgmCreationDisabled = computed(() =>
      Boolean(
        (!editMode.value && existActiveCgm.value && newMedicalTestForm.value.testType == MedicalTestTypeValue.CGM) ||
          submitting.value,
      ),
    );

    onMounted(async () => {
      await fillSpecialistDropdown();
    });

    const onSubmittForm = async () => {
      showValidation.value = true;

      if (!valid.value) {
        return;
      }
      submitting.value = true;
      const result = await sendRequest();

      if (result instanceof ServiceError) {
        toast.add({ severity: 'error', summary: t(`messages.error.medicalTestCreation`), life: 3000 });
      } else {
        emit('new-medical-test');
        showDialog.value = false;
      }

      submitting.value = false;
    };

    async function sendRequest() {
      let result: any = null;
      if (editMode.value) {
        if (!props.medicalTestOnEdit?.id) return;

        const medicalTest = await medicalTestService.find(props.patient.id, props.medicalTestOnEdit.id);
        if (medicalTest instanceof ServiceError) {
          return medicalTest;
        }
        medicalTest.name = newMedicalTestForm.value.name;
        const updateSpecialistsData = {
          specialistId: newMedicalTestForm.value.specialistId,
          viewerSpecialistIds: newMedicalTestForm.value.viewerSpecialistIds || [],
        };
        result = await medicalTestService.update(
          props.patient.id,
          props.medicalTestOnEdit?.id,
          medicalTest,
          updateSpecialistsData,
        );
        toast.add({ severity: 'success', summary: t(`messages.notifications.successUpdateMedicalTest`), life: 3000 });
      } else {
        result = await medicalTestService.create(
          props.patient.id,
          getMedicalTestId(newMedicalTestForm.value.testType),
          new MedicalTestCreateForm(newMedicalTestForm.value),
        );
      }

      return result;
    }

    const openDialog = (medicalTest?: MedicalTestTableDto) => {
      IsNameInputPristine.value = true;
      showValidation.value = false;
      showDialog.value = true;
      if (medicalTest) {
        setEditValuesInForm(medicalTest);
      } else {
        setDefaultValuesInForm();
      }
    };

    async function fillSpecialistDropdown() {
      const result = await userService.findAll([UserRole.SPECIALIST]);
      if (!(result instanceof ServiceError)) {
        specialistOptions.value = result;
      }
    }

    function setDefaultValuesInForm() {
      newMedicalTestForm.value = {} as MedicalTestCreateFormDto;
      newMedicalTestForm.value.date = new Date();
      newMedicalTestForm.value.hour = new Date();
      if (userIsSpecialist.value && userProfile.value) {
        const currentSpecialist = specialistOptions.value?.find(
          (specialist: UserTableDto) => specialist.id == userProfile.value?.id,
        );
        if (currentSpecialist && !newMedicalTestForm.value.specialistId) {
          newMedicalTestForm.value.specialistId = currentSpecialist.professionalId;
        }
      }
    }

    function setEditValuesInForm(medicalTest: MedicalTestTableDto) {
      newMedicalTestForm.value = {} as MedicalTestCreateFormDto;
      newMedicalTestForm.value.date = new Date(medicalTest.date);
      newMedicalTestForm.value.hour = new Date(medicalTest.date);
      newMedicalTestForm.value.name = medicalTest.name;
      newMedicalTestForm.value.specialistId = medicalTest.specialist.professionalId;

      if (userIsSpecialist.value && userProfile.value) {
        const currentSpecialist = specialistOptions.value?.find(
          (specialist: UserTableDto) => specialist.id == userProfile.value?.id,
        );
        if (currentSpecialist && !newMedicalTestForm.value.specialistId) {
          newMedicalTestForm.value.specialistId = currentSpecialist.professionalId;
        }
      }
      newMedicalTestForm.value.testType = medicalTest.medicalTestTypeValue;
      newMedicalTestForm.value.viewerSpecialistIds = medicalTest.viewerSpecialists.map(
        (specialist) => specialist.professionalId,
      );
    }

    function onTestTypeChange(event: any) {
      if (editMode.value) {
        return;
      }
      if (!IsNameInputPristine.value) {
        return;
      }
      const type = event.value;

      const translatedType = t(`medicalTestTypes.${type}`);
      const day = parseInt(moment().locale(i18n.global.locale.value).format('DD'));
      const week = day < 15 ? 'I' : 'II';
      const month = moment().locale(i18n.global.locale.value).format('MMMM');
      const year = moment().locale(i18n.global.locale.value).format('YY');
      const suggestedTitle = `${translatedType} ${week} - ${month} ${year}`;

      newMedicalTestForm.value.name = suggestedTitle;
    }

    function onTestNameInput() {
      IsNameInputPristine.value = false;
    }

    return {
      cgmCreationDisabled,
      MedicalTestTypeValue,
      showDialog,
      route,
      submitting,
      medicalTestTypeOptions,
      specialistOptions,
      newMedicalTestForm,
      validatedMedicalTestBaseForm,
      validatedCalorimetryForm,
      userIsSpecialist,
      showValidation,
      selectableSpecialistOptions,
      existActiveCgm,
      displayInlineWarning,
      confirmButtonLabel,
      modalLabel,
      editMode,
      IsNameInputPristine,
      onSpecialistChange,
      onSubmittForm,
      openDialog,
      onTestTypeChange,
      onTestNameInput,
    };
  },
});
