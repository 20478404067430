import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between" }
const _hoisted_2 = { class: "p-input-icon-left p-col-4" }
const _hoisted_3 = { class: "specialist-item-wraper" }
const _hoisted_4 = { class: "specialist-item-value" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_HealzButton = _resolveComponent("HealzButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_MedicalTestCard = _resolveComponent("MedicalTestCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_MedicalTestCreateUpdateFormModal = _resolveComponent("MedicalTestCreateUpdateFormModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.medicalTests && _ctx.medicalTests.length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.tableList)
            ? (_openBlock(), _createBlock(_component_DataTable, {
                key: 0,
                ref: "dt",
                filters: _ctx.filters,
                "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
                value: _ctx.medicalTests,
                "data-key": "id",
                rows: 10,
                "filter-display": "menu",
                "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                paginator: _ctx.showPaginator,
                "responsive-layout": "scroll",
                "global-filter-fields": ['name', 'medicalTest.medicalTestType'],
                "sort-field": "date",
                "sort-order": -1,
                "removable-sort": "",
                "row-class": _ctx.rowHover,
                onRowClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onMedicalTestDetail($event.data)))
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("span", _hoisted_2, [
                      _cache[5] || (_cache[5] = _createElementVNode("i", {
                        class: "icon-search",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createVNode(_component_InputText, {
                        modelValue: _ctx.filters['global'].value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                        placeholder: _ctx.$t('table.search.medicalTest')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _createVNode(_component_Button, {
                      type: "button",
                      class: "p-button p-component p-button-secondary p-button-medium p-button-icon",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.initFilters()))
                    }, {
                      default: _withCtx(() => [
                        _cache[6] || (_cache[6] = _createElementVNode("i", {
                          class: "icon-trash_empty",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('table.clearFilters')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "name",
                    header: _ctx.$t('common.name')
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(slotProps.data?.name), 1)
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_Column, {
                    field: "specialists",
                    header: _ctx.$t('patient.specialists-short')
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("p", _hoisted_3, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(slotProps.data?.specialist?.name) + " " + _toDisplayString(slotProps.data?.specialist?.surname), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.viewerSpecialists, (viewerSpecialist) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: viewerSpecialist.id,
                            class: "specialist-item-value"
                          }, _toDisplayString(viewerSpecialist?.name) + " " + _toDisplayString(viewerSpecialist?.surname), 1))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_Column, {
                    field: "state",
                    header: _ctx.$t('common.state')
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`medicalTest.state.${slotProps.data.state}`)), 1)
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_Column, {
                    field: "origin",
                    header: _ctx.$t('common.kind'),
                    sortable: true,
                    "sort-field": "medicalTestTypeValue"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_Tag, {
                        class: _normalizeClass(_ctx.getTagFromTestType(slotProps.data.medicalTestTypeValue))
                      }, {
                        default: _withCtx(() => [
                          (slotProps.data.medicalTestTypeValue)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t(`medicalTestTypes.${slotProps.data.medicalTestTypeValue}`)), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(" --- ")
                              ], 64))
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_Column, {
                    field: "startDate",
                    header: _ctx.$t('common.date'),
                    "data-type": "date",
                    "filter-field": "date",
                    "show-filter-match-modes": false,
                    "show-apply-button": false,
                    "sort-field": "date",
                    sortable: ""
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(_ctx.dateFormat.formatDateAndHour(_ctx.dateFormat.formatLocalTimezone(slotProps.data.date))), 1)
                    ]),
                    filter: _withCtx(({ filterModel, filterCallback }) => [
                      _createVNode(_component_Calendar, {
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        "date-format": _ctx.dateFormat.dateFormat(),
                        placeholder: _ctx.dateFormat.dateFormat(),
                        onDateSelect: ($event: any) => (filterCallback())
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "date-format", "placeholder", "onDateSelect"])
                    ]),
                    filterclear: _withCtx(() => _cache[7] || (_cache[7] = [])),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('table.actions')
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_HealzButton, {
                        disabled: !_ctx.userCanEditMedicalTest(slotProps.data),
                        tooltipText: _ctx.$t('common.no-permission'),
                        class: "p-button-table",
                        onClick: ($event: any) => (_ctx.onMedicalTestEdit(slotProps.data))
                      }, {
                        content: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createElementVNode("i", {
                            class: "icon-edit",
                            "aria-hidden": "true"
                          }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["disabled", "tooltipText", "onClick"]),
                      _createVNode(_component_HealzButton, {
                        disabled: !_ctx.userCanSeeMedicalTestDetail(slotProps.data),
                        tooltipText: _ctx.$t('common.no-permission'),
                        class: "p-button-table",
                        onClick: ($event: any) => (_ctx.onMedicalTestDetail(slotProps.data))
                      }, {
                        content: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("i", {
                            class: "icon-show",
                            "aria-hidden": "true"
                          }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["disabled", "tooltipText", "onClick"])
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              }, 8, ["filters", "value", "paginator", "row-class"]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.medicalTests, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createVNode(_component_MedicalTestCard, {
                      medicalTest: item,
                      disableButton: !_ctx.isSpecialistUser,
                      onMedicalTestViewDetailClick: ($event: any) => (_ctx.onMedicalTestDetail(item))
                    }, null, 8, ["medicalTest", "disableButton", "onMedicalTestViewDetailClick"])
                  ]))
                }), 128))
              ]))
        ], 64))
      : (_openBlock(), _createBlock(_component_EmptyState, {
          key: 1,
          heading: _ctx.$t('medicalTest.isEmtyTitle'),
          text: _ctx.$t('medicalTest.isEmptyMessage'),
          secondary: false,
          icon: "icon-bar_chart"
        }, null, 8, ["heading", "text"])),
    (_ctx.patient)
      ? (_openBlock(), _createBlock(_component_MedicalTestCreateUpdateFormModal, {
          key: 2,
          ref: "editMedicalTestModalRef",
          "edit-mode": true,
          patient: _ctx.patient,
          medicalTestList: _ctx.medicalTests,
          medicalTestOnEdit: _ctx.medicalTestOnEdit,
          onNewMedicalTest: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('new-medical-test')))
        }, null, 8, ["patient", "medicalTestList", "medicalTestOnEdit"]))
      : _createCommentVNode("", true)
  ], 64))
}