
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  emits: ['click', 'clickStop'],
  props: {
    disabled: {
      type: Boolean as () => boolean,
      default: false,
    },
    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
    id: {
      type: String as () => string,
      required: false,
    },
    buttonText: {
      type: String,
    },
    buttonClass: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const onClick = (event: any) => {
      event.preventDefault();
      if (props.disabled) {
        return;
      }
      emit('click');
    };

    const onClickStop = (event: any) => {
      event.preventDefault();
      if (props.disabled) {
        return;
      }
      emit('clickStop');
    };

    return { onClick, onClickStop };
  },
});
