import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "details-row" }
const _hoisted_2 = {
  key: 0,
  class: "detail"
}
const _hoisted_3 = {
  key: 1,
  class: "detail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HealzButton = _resolveComponent("HealzButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.getColorClass(_ctx.medicalTest.medicalTestTypeValue)])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.medicalTest.name), 1),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.medicalTest.state)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("i", {
                class: "icon-loading",
                "aria-hidden": "true"
              }, null, -1)),
              _createTextVNode(_toDisplayString(_ctx.$t(`medicalTest.state.${_ctx.medicalTest.state}`)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.date)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("i", {
                class: "icon-calendar",
                "aria-hidden": "true"
              }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.date), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_HealzButton, {
      disabled: _ctx.disableButton,
      buttonText: _ctx.$t('common.see'),
      tooltipText: _ctx.$t('common.no-permission'),
      class: "p-button-medium",
      onClick: _ctx.onMedicalTestDetail
    }, null, 8, ["disabled", "buttonText", "tooltipText", "onClick"])
  ], 2))
}